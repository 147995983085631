<template>
  <v-app class>
    <div>
      <v-alert
        v-model="alert"
        border="left"
        dark
        dismissible
        class="alert"
        :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}"
      >
{{alertMessage}}
      </v-alert>
    </div>
    <div class="row">
      <div class="col-md-12 ml-auto mr-auto">
        <v-container>
          <div class="mb-8 mt-8 text-center">
            <h1>List of  Personnel</h1>
          </div>
          <div class="row">
            <!-- <div class="col-md-6">
              <v-text-field
                v-model="searchPersonnel"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </div> -->
            <div class="col-md-6">
              <SelectField
                :items="contractEmployers"
                v-model="contractEmployerId"
                :label="'Contractor Employer'"
                :itemText="'name'"
                :itemValue="'id'"
                @onChange="contractEmployerChange"
              />
            </div>
            <div class="col-md-6">
              <div class="export-container">
                  <SelectField :items="downloadFormat" v-model="formData.selectedFormat" :label="'Download Format'" :itemText="'name'" :itemValue="'id'" :returnObject="true" @input="onSelectedFormat" />
                  <Button :label="'Download'" :btnType="'Submit'" @onClick="onDownload" class="" />
              </div>
            </div>
          </div>
          <v-data-table
            loading-text="Loading personnel. Please wait...."
            :loading="isLoading"
            :headers= 'headers'
            :items="employeeList"
            :search="searchPersonnel"
            item-key="id"
          >
          <template v-slot:item.lastModifiedDate="{ item }">
            {{item.lastModifiedDate | formatDate}}
          </template>
          <template v-slot:item.firstName="{ item }">
            <span>{{ item.firstName }}, {{ item.lastName }} {{ item.middleName}}</span>
          </template>
          </v-data-table>
        </v-container>
      </div>
    </div>
  </v-app>
</template>
<script>

import SelectField from '@/components/ui/form/SelectField.vue'
// import TextField from '@/components/ui/form/TextField.vue'
import Button from '@/components/ui/form/Button.vue'

// import Dialog from '@/components/ui/Dialog.vue'
import { userService, employeeService, contractEmployerService } from '@/services'
import Roles from '@/utils/roles'

export default {
  components: {
    SelectField,
    Button
    // TextField,
    // Dialog
  },
  data () {
    return {
      search: '',
      userRole: '',
      appRoles: {},
      isLoading: false,
      formData: {
        name: '',
        id: ''
      },
      contractEmployerId: 0,
      employeeList: [],
      contractEmployers: [],
      personnel: {},
      searchPersonnel: '',
      valid: false,
      isSavingUser: false,
      alertMessage: '',
      allLoading: 0,
      alert: false,
      alertType: '',
      headers: [
        {
          text: 'Cai',
          value: 'cai',
          sortable: false
        },
        {
          text: 'Personnel Name',
          value: 'firstName'
        },
        {
          text: 'Prnumber',
          value: 'prnumber'
        },
        {
          text: 'Charge Code',
          value: 'chargeCode'
        },
        {
          text: 'Job Title',
          value: 'jobTitle.name'
        },
        {
          text: 'Email',
          value: 'email'
        },
        {
          text: 'Location',
          value: 'location.name'
        },
        {
          text: 'Department',
          value: 'department.name'
        },
        {
          text: 'Contractor Employer',
          value: 'contractEmployer.name'
        },
        {
          text: 'Status',
          value: 'employeeStatus'
        }
        // {
        //   text: 'Modified By',
        //   value: 'modifiedBy',
        //   sortable: false
        // },
        // {
        //   text: 'Date Modified',
        //   value: 'lastModifiedDate'
        // }
      ],
      downloadFormat: [
        {
          name: 'CSV',
          id: 'csv'
        },
        {
          name: 'Excel',
          id: 'excel'
        },
        {
          name: 'PDF',
          id: 'pdf'
        }
      ],
      selectedFormat: ''
    }
  },
  computed: {
    // employees () {
    //   // this.employeeList = this.$store.getters.employees
    //   return this.$store.getters.employees
    // }
  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
      setTimeout(() => { this.alert = false }, 5000)
    },
    edit (item) {
      this.$router.push(`/personnel-data/edit/${item.id}`)
    },
    openDeleteModal (item) {
      this.personnel = item
      this.$refs.deleteModal.toggleModal()
      this.formData.name = `${item.firstName}, ${item.lastName} ${item.middleName}`
    },
    resetForm () {
      this.formData.name = ''
      this.formData.id = ''
    },
    cancel (modal) {
      if (modal === 'editModal') {
        this.$refs.editModal.toggleModal()
      }
      if (modal === 'deleteModal') {
        this.$refs.deleteModal.toggleModal()
      }
    },
    contractEmployerChange (item) {
      this.employeeList = []
      this.isLoading = true
      if (item === 0.1) {
        this.getAllEmployee()
        return
      }
      employeeService.getEmployeesByEmployerId(item).then(result => {
        this.employeeList = result.data.items
        this.isLoading = false
      }).catch(() => {
        this.isLoading = false
      })
    },
    getAllEmployee () {
      employeeService.getAllEmployees().then(result => {
        this.employeeList = result.data.items
        this.isLoading = false
      }).catch(() => {
        this.isLoading = false
      })
    },
    onSelectedFormat (value) {
      this.selectedFormat = value.id
    },
    onDownload () {

    }
  },
  mounted () {
    this.isLoading = true
    this.appRoles = Roles

    userService.getCurrentUser().then(result => {
      this.userRole = result.data.roleName
    })

    contractEmployerService.getAllContractEmployers().then((result) => {
      this.contractEmployers = result.data.items
      this.contractEmployers.unshift({ 'id': 0.1, 'name': 'All' })
    })

    this.getAllEmployee()

    // this.$store
    //   .dispatch(FETCH_EMPLOYEES)
    //   .then(data => {
    //     console.clear()
    //     console.log('data:', data)
    //   })
    //   .catch(() => {
    //     // if (error) {
    //     //   this.showAlertMessage('Unable to display departments', 'error')
    //     // }
    //   })
  }
}
</script>
<style scoped>
.export-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.search-btn {
  background-color: #e6e3e3;
  height: 40px;
  border-radius: 3px !important;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
</style>
